import React from 'react'

const NoPage = () => {

  return (
    <div>
      Wrong page
    </div>
  )
}

export default NoPage